import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroPagesCenter from "../../components/heroes/hero-pages-center"
import ComparisonData from "../../content/comparison/index.json"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CompetitorComparison from "../../components/comparison/competitor"
import DemoTrial from "../../components/ctas/demo-trial"

const ComparisonIndexPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale} title={ComparisonData[intl.locale].title} description={ComparisonData[intl.locale].text} pathname={location.pathname} />
        <HeroPagesCenter theme={`primary`} content={ComparisonData[intl.locale]} />
        <div className={`comparison`}>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className={`vs`}>
                            <div className={`circle`}>
                                <div className={`circle-content`}>
                                    <div className={`emoji`}>⚔</div>
                                    <div className={`text`}>vs</div>
                                </div>
                            </div>
                        </div>
                        <div className={`top-overflow-wrapper`}>
                            <div className={`product-col main-col`}>
                                <div className={`product-info`}>
                                    <div className={`product-options`}>
                                        <div className={`product-options read-only`}>
                                            {ComparisonData[intl.locale].notificare.title}
                                        </div>
                                        <div className={`text`}>
                                            {ComparisonData[intl.locale].notificare.text}
                                        </div>
                                    </div>
                                </div>
                                <div className={`product-features`}>
                                    {ComparisonData[intl.locale].notificare.features.map((feature, i) => {
                                        if (feature.section) {
                                            return (
                                              <div key={i} className={`section`}>{feature.title}</div>
                                            )
                                        } else {
                                            if (feature.icon) {
                                                return (
                                                  <div key={i} className={`feature`}><FontAwesomeIcon icon="check" />{feature.feature}</div>
                                                )
                                            } else {
                                                return (
                                                  <div key={i} className={`feature na`}><FontAwesomeIcon icon="times" />{feature.feature}</div>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={`top-overflow-wrapper`}>
                            <CompetitorComparison content={ComparisonData[intl.locale].others} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <DemoTrial />
    </Layout>
)

export default injectIntl(ComparisonIndexPage)
