import React from "react";
import { injectIntl } from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class CompetitorComparison extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      competitor: null
    }
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(document.location.search);
    if (searchParams && searchParams.has("p")) {

      this.props.content.forEach((p) => {
        if (searchParams.get("p") === p.title) {
          this.setState({
            competitor: p
          });
        }
      });

    } else {
      this.setState({
        competitor: this.props.content[0]
      });
    }
  }

  handleCompetitorChange = (e) => {
    if (e && e.target && e.target.value) {
      if (this.state.competitor.title !== e.target.value) {
        window.location = "/comparison/?p=" + e.target.value;
      }
    }
  }

  render() {
    if (this.state.competitor) {
      return (
        <div className={`competitor-comparison`}>
          <div className={`product-col`}>
            <div className={`product-info`}>
              <div className={`product-options`}>
                <div className={`product-options-selector`}>
                  <select value={this.state.competitor.title} onChange={this.handleCompetitorChange} onBlur={this.handleCompetitorChange}>
                    {this.props.content.map((competitor, i) => {
                      return (
                        <option key={i} value={competitor.title}>
                          {competitor.title}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className={`text`}>
                  {this.state.competitor.text}
                </div>
              </div>
            </div>
            <div className={`product-features`}>
              {this.state.competitor.features.map((feature, i) => {

                if (feature.section) {
                  return (
                    <div key={i} className={`section`}>{feature.title}</div>
                  )
                } else {
                  if (feature.icon) {
                    return (
                      <div key={i} className={`feature`}><FontAwesomeIcon icon="check" />{feature.feature}</div>
                    )
                  } else {
                    return (
                      <div key={i} className={`feature na`}><FontAwesomeIcon icon="times" />{feature.feature}</div>
                    )
                  }
                }

              })}
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(CompetitorComparison)
